.accblock {
  padding-top: 90px;
  padding-bottom: 20px;
  padding-right: 20px;
  padding-left: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #656565;
}
@media screen and (min-width: 3000px) {
  .accblock {
    padding-top: 135px;
    padding-bottom: 30px;
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media screen and (max-width: 900px) {
  .accblock {
    flex-direction: column-reverse;
    padding-top: 120px;
  }
}
.accblock--reverse {
  flex-direction: row-reverse !important;
}
@media screen and (max-width: 900px) {
  .accblock--reverse {
    flex-direction: column-reverse !important;
  }
}
.accblock--left {
  flex-basis: 50%;
  text-align: justify;
}
@media screen and (max-width: 900px) {
  .accblock--left {
    text-align: left;
  }
}
.accblock--right {
  flex-basis: 50%;
  text-align: justify;
}
.accblock--image {
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 900px) {
  .accblock--image {
    margin-top: 20px;
  }
}
.accblock--text {
  padding-right: 40px;
  padding-left: 60px;
  box-sizing: border-box;
  width: 100%;
}
@media screen and (min-width: 3000px) {
  .accblock--text {
    padding-right: 60px;
    padding-left: 90px;
  }
}
.accblock--text p {
  font-size: 16px;
  line-height: 1.4;
}
@media screen and (min-width: 3000px) {
  .accblock--text p {
    font-size: 24px;
  }
}
@media screen and (max-width: 900px) {
  .accblock--text {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 0;
    padding-left: 0;
  }
}
.accblock--header {
  padding: 0 60px;
  box-sizing: border-box;
}
@media screen and (min-width: 3000px) {
  .accblock--header {
    padding: 0 90px;
  }
}
.panel {
  background-color: #fff;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}
.accordion--button {
  cursor: pointer;
  padding: 20px;
  width: 100%;
  text-align: left;
  border: none;
  outline: none;
  transition: 0.4s;
  font-size: 20px;
  background-color: #b3b3b3;
  color: #fff;
  font-family: "Barlow Condensed", sans-serif;
  display: flex;
  justify-content: space-between;
}
@media screen and (min-width: 3000px) {
  .accordion--button {
    padding: 30px;
    font-size: 30px;
  }
}
.accordion--button p {
  margin: 0;
}
.accordion--button--active {
  background-color: #656565;
}
.accordion--icon {
  transition: transform 0.6s ease;
}
.accordion--icon--rotate {
  transform: rotate(-180deg);
}
.accordion .swiper-pagination {
  top: 20px;
  left: 15px;
  font-size: 15px;
  text-align: initial;
}
@media screen and (min-width: 3000px) {
  .accordion .swiper-pagination {
    top: 30px;
    left: 22.5px;
    font-size: 22.5px;
  }
}
.accordion .swiper-pagination-bullet {
  text-align: center;
  opacity: 1;
  width: 160px;
  height: initial;
  padding: 3px 10px;
  border-radius: 10px;
  background-color: #b3b3b3;
  color: #fff;
  margin-bottom: 10px !important;
  font-size: 15px;
}
@media screen and (min-width: 3000px) {
  .accordion .swiper-pagination-bullet {
    width: 240px;
    padding: 4.5px 15px;
    border-radius: 15px;
    margin-left: 6px !important;
    margin-right: 6px !important;
    margin-bottom: 15px !important;
    font-size: 22.5px;
  }
}
.accordion .swiper-pagination-bullet-active {
  background-color: #656565;
}
.backtotop {
  position: fixed;
  right: 15px;
  bottom: 15px;
  border-radius: 50%;
  font-size: 30px;
  color: #b3b3b3;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.7;
  visibility: hidden;
  transition: 0.5s;
}
@media screen and (min-width: 3000px) {
  .backtotop {
    font-size: 45px;
    right: 22.5px;
    bottom: 22.5px;
  }
}
.backtotop:hover {
  cursor: pointer;
}
.backtotop--show {
  visibility: visible;
  animation-name: fade_in_scroll;
  animation-duration: 0.5s;
}
.backtotop--hide {
  animation-name: fade_out_scroll;
  animation-duration: 0.5s;
}
@-moz-keyframes fade_in_scroll {
  from {
    opacity: 0;
    bottom: -30px;
  }
  to {
    opacity: 0.7;
    bottom: 15px;
  }
}
@-webkit-keyframes fade_in_scroll {
  from {
    opacity: 0;
    bottom: -30px;
  }
  to {
    opacity: 0.7;
    bottom: 15px;
  }
}
@-o-keyframes fade_in_scroll {
  from {
    opacity: 0;
    bottom: -30px;
  }
  to {
    opacity: 0.7;
    bottom: 15px;
  }
}
@keyframes fade_in_scroll {
  from {
    opacity: 0;
    bottom: -30px;
  }
  to {
    opacity: 0.7;
    bottom: 15px;
  }
}
@-moz-keyframes fade_out_scroll {
  from {
    opacity: 0.7;
  }
  to {
    opacity: 0;
  }
}
@-webkit-keyframes fade_out_scroll {
  from {
    opacity: 0.7;
  }
  to {
    opacity: 0;
  }
}
@-o-keyframes fade_out_scroll {
  from {
    opacity: 0.7;
  }
  to {
    opacity: 0;
  }
}
@keyframes fade_out_scroll {
  from {
    opacity: 0.7;
  }
  to {
    opacity: 0;
  }
}
.footer {
  width: 100%;
  color: #fff;
  background-color: #838383;
  height: 60px;
  display: flex;
  align-items: center;
  margin-top: auto;
}
@media screen and (min-width: 3000px) {
  .footer {
    height: 90px;
  }
}
.footer--links span {
  margin-right: 20px;
}
@media screen and (min-width: 3000px) {
  .footer--links span {
    margin-right: 40px;
  }
}
.footer a {
  text-decoration: none;
  color: #e7e7e7;
  transition: 0.3s;
  font-size: 20px;
  cursor: pointer;
}
@media screen and (min-width: 3000px) {
  .footer a {
    font-size: 30px;
  }
}
.footer a:hover {
  color: #b3b3b3;
}
.header {
  background-image: url("ff829f7764c113afb345858c61a3000f.png");
  background-size: cover;
  background-position: center bottom;
  background-repeat: no-repeat;
  background-color: #d9d9d9;
  height: 100%;
}
.header--content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}
.header--logo {
  flex-basis: 33%;
}
@media screen and (max-width: 900px) {
  .header--logo {
    flex-basis: 50%;
  }
}
@media screen and (max-width: 600px) {
  .header--logo {
    flex-basis: 65%;
  }
}
.header--logo img {
  width: 100%;
  padding: 30px 0;
}
@media screen and (min-width: 3000px) {
  .header--logo img {
    padding: 45px 0;
  }
}
@media screen and (max-width: 900px) {
  .header--logo img {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
.header--seperator {
  background-color: #ffe38f;
  position: relative;
  display: flex;
  font-size: 20px;
}
@media screen and (min-width: 3000px) {
  .header--seperator {
    font-size: 30px;
  }
}
.header--seperator--right {
  background-color: #d5b149;
  position: absolute;
  right: 0;
  width: 50%;
  height: 100%;
  z-index: 1;
}
.header--seperator--container {
  background-color: #ffe38f;
  display: flex;
  justify-content: flex-end;
  z-index: 10;
}
.header--seperator--nav {
  background-color: #d5b149;
  padding: 4px 0;
}
@media screen and (min-width: 3000px) {
  .header--seperator--nav {
    padding: 6px 0;
  }
}
.header--seperator--nav a {
  text-decoration: none;
  color: #fff;
  margin-left: 15px;
  cursor: pointer;
}
@media screen and (min-width: 3000px) {
  .header--seperator--nav a {
    margin-left: 22.5px;
  }
}
.gallery-frame {
  background-color: #e7e7e7;
  padding: 15px 0;
  margin-bottom: 45px;
  border: solid 1px #b3b3b3;
  border-top: none;
  box-shadow: 2px 2px 2px rgba(0,0,0,0.2);
  position: relative;
  z-index: 0;
  overflow: hidden;
}
@media screen and (min-width: 3000px) {
  .gallery-frame {
    padding-top: 22.5px 0;
    box-shadow: 3px 3px 3px rgba(0,0,0,0.2);
    margin-bottom: 67.5px;
  }
}
.gallery-frame--segments {
  margin-top: 45px;
  display: flex;
  justify-content: space-around;
  background-color: #e7e7e7;
  cursor: pointer;
}
@media screen and (min-width: 3000px) {
  .gallery-frame--segments {
    margin-top: 67.5px;
  }
}
.gallery-frame--segments a {
  flex-basis: 360px;
  border-top: solid 1px #b3b3b3;
  border-bottom: solid 1px #b3b3b3;
  border-right: solid 1px #b3b3b3;
  padding: 3px 10px;
  display: inline-block;
  text-decoration: none;
  text-transform: uppercase;
  color: #838383;
  font-size: 20px;
}
.gallery-frame--segments a:first-child {
  border-left: solid 1px #b3b3b3;
}
@media screen and (min-width: 3000px) {
  .gallery-frame--segments a {
    flex-basis: 540px;
    padding: 4.5px 15px;
    font-size: 30px;
  }
}
.gallery-frame--segments--active {
  border-bottom: none !important;
  box-shadow: 2px 2px 2px rgba(0,0,0,0.2);
  font-weight: 500;
}
@media screen and (min-width: 3000px) {
  .gallery-frame--segments--active {
    box-shadow: 3px 3px 3px rgba(0,0,0,0.2);
  }
}
.gallery-frame .swiper-container {
  margin: 0 15px;
}
@media screen and (min-width: 3000px) {
  .gallery-frame .swiper-container {
    margin: 7.5px 22.5px;
  }
}
.gallery-frame .swiper-container .swiper-slide img {
  width: 100%;
}
.gallery-frame .swiper-container .swiper-pagination-bullet {
  height: 10px;
  width: 10px;
  margin: 0 9px !important;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
  opacity: 0.4;
  box-shadow: 2px 2px 2px #000;
}
@media screen and (min-width: 3000px) {
  .gallery-frame .swiper-container .swiper-pagination-bullet {
    height: 15px;
    width: 15px;
    margin: 0 13.5px !important;
    box-shadow: 3px 3px 3px rgba(0,0,0,0.1);
  }
}
.gallery-frame .swiper-container .swiper-pagination-bullet:hover {
  background-color: #fff;
}
.gallery-frame .swiper-container .swiper-pagination-bullet-active {
  background-color: #fff;
}
.gallery-frame .swiper-container .swiper-button-next,
.gallery-frame .swiper-container .swiper-button-prev {
  color: #eee;
  opacity: 0.8;
}
.gallery-frame .swiper-container-active {
  background-color: #fff;
}
.gallery--hidden {
  height: 0;
  visibility: hidden;
}
.swiper--hide {
  display: none !important;
}
.swiper--button:hover {
  cursor: pointer;
}
.swiper--caption {
  position: absolute;
  bottom: 3px;
  background-color: #fff;
  opacity: 0.85;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  color: #656565;
  font-size: 18px;
  text-align: center;
}
@media screen and (min-width: 3000px) {
  .swiper--caption {
    bottom: 3px;
    height: 75px;
    padding: 0 30px;
    font-size: 27px;
  }
}
.ril__outer {
  background-color: rgba(0,0,0,0.5);
}
.ril__navButtonPrev {
  margin-left: 20px;
  background: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='chevron-circle-left' class='svg-inline--fa fa-chevron-circle-left fa-w-16' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='white' d='M256 504C119 504 8 393 8 256S119 8 256 8s248 111 248 248-111 248-248 248zM142.1 273l135.5 135.5c9.4 9.4 24.6 9.4 33.9 0l17-17c9.4-9.4 9.4-24.6 0-33.9L226.9 256l101.6-101.6c9.4-9.4 9.4-24.6 0-33.9l-17-17c-9.4-9.4-24.6-9.4-33.9 0L142.1 239c-9.4 9.4-9.4 24.6 0 34z'%3E%3C/path%3E%3C/svg%3E") no-repeat center;
}
@media screen and (min-width: 3000px) {
  .ril__navButtonPrev {
    margin-left: 30px;
  }
}
.ril__navButtonNext {
  margin-right: 20px;
  background: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='chevron-circle-right' class='svg-inline--fa fa-chevron-circle-right fa-w-16' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='white' d='M256 8c137 0 248 111 248 248S393 504 256 504 8 393 8 256 119 8 256 8zm113.9 231L234.4 103.5c-9.4-9.4-24.6-9.4-33.9 0l-17 17c-9.4 9.4-9.4 24.6 0 33.9L285.1 256 183.5 357.6c-9.4 9.4-9.4 24.6 0 33.9l17 17c9.4 9.4 24.6 9.4 33.9 0L369.9 273c9.4-9.4 9.4-24.6 0-34z'%3E%3C/path%3E%3C/svg%3E") no-repeat center;
}
@media screen and (min-width: 3000px) {
  .ril__navButtonNext {
    margin-right: 30px;
  }
}
.ril__toolbar {
  background-color: initial;
}
.ril__image {
  width: 95%;
  transform: scale3d(0.875829, 0.875829, 1) !important;
}
.ril__caption {
  justify-content: center;
  font-size: 20px;
}
@media screen and (min-width: 3000px) {
  .ril__caption {
    font-size: 30px;
  }
}
.slidermobile .swiper-container {
  margin-top: 40px;
  margin-bottom: 40px;
}
.slidermobile .swiper-container .swiper-slide img {
  width: 100%;
}
.slidermobile .swiper-container .swiper-pagination-bullet {
  height: 10px;
  width: 10px;
  margin: 0 9px !important;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
  opacity: 0.4;
  box-shadow: 2px 2px 2px #000;
}
.slidermobile .swiper-container .swiper-pagination-bullet:hover {
  background-color: #fff;
}
.slidermobile .swiper-container .swiper-pagination-bullet-active {
  background-color: #fff;
}
.slidermobile .swiper-container .swiper-button-next,
.slidermobile .swiper-container .swiper-button-prev {
  color: #eee;
  opacity: 0.8;
}
.slidermobile .swiper-container-active {
  background-color: #fff;
}
.grid-2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 60px 0;
  color: #656565;
  font-size: 18px;
}
@media screen and (min-width: 3000px) {
  .grid-2 {
    padding: 90px 0;
    font-size: 27px;
  }
}
@media screen and (max-width: 900px) {
  .grid-2 {
    flex-direction: column-reverse;
    padding-top: 0;
  }
}
@media screen and (max-width: 600px) {
  .grid-2 {
    padding-bottom: 30px;
  }
}
.grid-2 table tr {
  vertical-align: top;
}
.grid-2 table tr td {
  padding-left: 10px;
}
@media screen and (min-width: 3000px) {
  .grid-2 table tr td {
    padding-left: 15px;
  }
}
.grid-2 table tr td:first-child {
  padding-left: 0;
}
.grid-2 a {
  color: #838383;
  text-decoration: none;
  transition: 0.5s;
}
.grid-2 a:hover {
  background-color: #b3b3b3;
  color: #e7e7e7;
}
.grid-2--no-padding-right {
  padding-top: 10px;
  padding-right: 0 !important;
}
@media screen and (min-width: 3000px) {
  .grid-2--no-padding-right {
    padding-top: 15px;
  }
}
.grid-2--flip-on-mobile {
  align-items: start !important;
}
@media screen and (max-width: 900px) {
  .grid-2--flip-on-mobile {
    flex-direction: column !important;
    align-items: start;
  }
}
.grid-2--bold {
  font-weight: 500;
}
.grid-2--header-only {
  font-size: 24px;
  text-transform: uppercase;
  margin: 0 60px;
}
@media screen and (min-width: 3000px) {
  .grid-2--header-only {
    font-size: 36px;
    margin: 0 90px;
  }
}
.grid-2--reverse {
  flex-direction: row-reverse !important;
}
.grid-2--reverse .grid-2--header-only {
  text-align: right;
}
@media screen and (max-width: 900px) {
  .grid-2--reverse {
    flex-direction: column-reverse !important;
  }
}
.grid-2--left {
  flex-basis: 50%;
  text-align: right;
  width: 100%;
}
@media screen and (max-width: 900px) {
  .grid-2--left {
    text-align: left;
  }
}
.grid-2--right {
  flex-basis: 50%;
  text-align: left;
  width: 100%;
}
.grid-2--image {
  width: 100%;
  height: 100%;
}
.grid-2--text {
  padding: 0 60px;
  box-sizing: border-box;
  width: 100%;
}
@media screen and (min-width: 3000px) {
  .grid-2--text {
    padding: 0 90px;
  }
}
@media screen and (max-width: 900px) {
  .grid-2--text {
    padding-top: 50px;
  }
}
@media screen and (max-width: 600px) {
  .grid-2--text {
    padding-top: 20px;
    padding-right: 20px;
    padding-left: 20px;
  }
}
.grid-2--header {
  padding: 0 60px;
  box-sizing: border-box;
}
@media screen and (min-width: 3000px) {
  .grid-2--header {
    padding: 0 90px;
  }
}
.progress-indicator-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}
@media screen and (min-width: 3000px) {
  .progress-indicator-container {
    padding: 30px;
  }
}
.email-container {
  display: flex;
  flex-direction: column;
  width: 360px;
  box-sizing: border-box;
  padding-left: 60px;
  font-size: 20px;
}
@media screen and (min-width: 3000px) {
  .email-container {
    width: 540px;
    padding-left: 90px;
    font-size: 30px;
  }
}
.email-container > * {
  margin-top: 10px !important;
}
@media screen and (min-width: 3000px) {
  .email-container > * {
    margin-top: 15px !important;
  }
}
.email-container:first-child {
  margin-top: 0 !important;
}
@media screen and (max-width: 900px) {
  .email-container {
    padding-top: 20px;
    padding-left: $distance-l;
    padding-right: $distance-l;
    width: 100%;
  }
}
@media screen and (max-width: 600px) {
  .email-container {
    padding-right: 20px;
    padding-left: 20px;
  }
}
.contact-icon-container {
  display: flex;
  justify-content: flex-end;
  width: 150px;
  margin-left: auto;
}
@media screen and (max-width: 900px) {
  .contact-icon-container {
    margin-left: 0;
    justify-content: flex-start;
  }
}
.move-down-slightly {
  padding-top: 10px;
}
.profile_table p {
  margin: 0;
}
.styled-price-list {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  margin-left: auto;
  margin-top: 20px;
}
@media screen and (max-width: 900px) {
  .styled-price-list {
    width: 100%;
  }
}
@media screen and (min-width: 3000px) {
  .styled-price-list {
    max-width: 600px;
    margin-top: 30px;
  }
}
.panel-prices {
  background-color: #fff;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}
@media screen and (max-width: 1080px) {
  .panel-prices {
    padding: 0 20px;
  }
}
.price-container {
  max-width: 815px;
  font-size: 18px;
}
@media screen and (min-width: 3000px) {
  .price-container {
    max-width: 1222.5px;
    font-size: 27px;
  }
}
.submit-stuff {
  height: 110px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media screen and (min-width: 3000px) {
  .submit-stuff {
    height: 165px;
  }
}
.uvp-container {
  margin-left: auto;
  font-size: 18px;
  margin-bottom: 10px;
}
@media screen and (min-width: 3000px) {
  .uvp-container {
    font-size: 27px;
    margin-bottom: 15px;
  }
}
.font-size-normal {
  font-size: 18px;
}
@media screen and (min-width: 3000px) {
  .font-size-normal {
    font-size: 27px;
  }
}
.price-display {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media screen and (min-width: 3000px) {
  .price-display {
    margin-top: 15px;
    margin-bottom: 15px;
  }
}
.subheader {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 20px;
  display: flex;
  align-items: center;
}
.subheader span {
  margin-right: 40px;
}
@media screen and (min-width: 3000px) {
  .subheader {
    margin-top: 15px;
    margin-bottom: 15px;
    font-size: 30px;
  }
  .subheader span {
    margin-right: 60px;
  }
}
.subheader-line {
  background-color: #838383;
  height: 1px;
  width: 550px;
  margin-left: auto;
}
@media screen and (max-width: 1080px) {
  .subheader-line {
    display: none;
  }
}
@media screen and (min-width: 3000px) {
  .subheader-line {
    height: 1.5px;
  }
}
body {
  box-sizing: border-box;
  margin: 0;
  font-family: "Barlow Condensed", sans-serif;
  -webkit-text-size-adjust: 100%;
}
* {
  box-sizing: border-box;
}
#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.container {
  width: 1080px;
  margin: 0 auto;
}
@media screen and (min-width: 3000px) {
  .container {
    width: 1620px;
  }
}
@media screen and (max-width: 1080px) {
  .container {
    width: 100%;
  }
}
.container--small {
  width: 1080px;
  margin: 0 auto;
}
@media screen and (min-width: 3000px) {
  .container--small {
    width: 1620px;
  }
}
@media screen and (max-width: 1080px) {
  .container--small {
    width: 95%;
  }
}
.bg-grey {
  background-color: #e7e7e7;
}
.bg-white {
  background-color: #fff;
}
.margin-top--regular {
  margin-top: 45px;
}
@media screen and (min-width: 3000px) {
  .margin-top--regular {
    margin-top: 67.5px;
  }
}
.margin-bottom--regular {
  margin-bottom: 45px;
}
@media screen and (min-width: 3000px) {
  .margin-bottom--regular {
    margin-bottom: 67.5px;
  }
}
.padding-top--regular {
  padding-top: 45px;
}
@media screen and (min-width: 3000px) {
  .padding-top--regular {
    padding-top: 67.5px;
  }
}
@media screen and (max-width: 900px) {
  .padding-top--regular--only--mobile {
    padding-top: 45px;
  }
}
.padding-top--small {
  padding-top: 20px;
}
@media screen and (min-width: 3000px) {
  .padding-top--small {
    padding-top: 30px;
  }
}
.padding-bottom--small {
  padding-bottom: 20px;
}
@media screen and (min-width: 3000px) {
  .padding-bottom--small {
    padding-bottom: 30px;
  }
}
.padding-bottom--regular {
  padding-bottom: 45px;
}
@media screen and (min-width: 3000px) {
  .padding-bottom--regular {
    padding-bottom: 67.5px;
  }
}
.padding-top--regular--desktop--only {
  padding-top: 45px;
}
@media screen and (min-width: 3000px) {
  .padding-top--regular--desktop--only {
    padding-top: 67.5px;
  }
}
@media screen and (max-width: 1080px) {
  .padding-top--regular--desktop--only {
    padding-top: 0;
  }
}
.padding-bottom--regular--desktop--only {
  padding-bottom: 45px;
}
@media screen and (min-width: 3000px) {
  .padding-bottom--regular--desktop--only {
    padding-bottom: 67.5px;
  }
}
@media screen and (max-width: 1080px) {
  .padding-bottom--regular--desktop--only {
    padding-bottom: 0;
  }
}
@media screen and (max-width: 600px) {
  .hide--mobile {
    display: none;
  }
}
.show--mobile {
  display: none;
}
@media screen and (max-width: 600px) {
  .show--mobile {
    display: initial;
  }
}
.text--h2 {
  font-size: 20px;
  font-weight: 500;
  text-transform: uppercase;
}
@media screen and (min-width: 3000px) {
  .text--h2 {
    font-size: 30px;
  }
}
.color-dark-grey {
  color: #656565;
}
.link {
  color: #656565;
}
.rte--box {
  max-width: 815px;
}
@media screen and (min-width: 3000px) {
  .rte--box {
    width: 1222.5px;
  }
}
.rte p {
  margin: 0;
  font-size: 18px;
  min-height: 20px;
}
@media screen and (min-width: 3000px) {
  .rte p {
    font-size: 27px;
    min-height: 30px;
  }
}
.rte ul {
  font-size: 18px;
}
@media screen and (min-width: 3000px) {
  .rte ul {
    font-size: 27px;
  }
}
.font--h1 {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 20px;
}
@media screen and (min-width: 3000px) {
  .font--h1 {
    font-size: 30px;
  }
}
.font--h2 {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 20px;
}
@media screen and (min-width: 3000px) {
  .font--h2 {
    font-size: 30px;
  }
}
.font--h3 {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 18px;
}
@media screen and (min-width: 3000px) {
  .font--h3 {
    font-size: 27px;
  }
}
.stop-scrolling {
  height: 100vh;
  overflow: hidden;
}
